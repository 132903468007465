import React from "react"

import Layout from "@components/layout"
import SEO from "@components/seo"

const AndroidThanksPage = () => (
  <Layout bodyClassName='android'>
    <SEO
      title="Android First Notice"
      meta={[
        {
          property: `robots`,
          content: `noindex`,
        },
      ]}
    />

    <section className="stripe stripe--hero stripe--centered">
      <div className="container container--lg">
        <div className="copy">
          <h1 className="pageTitle">Interested in Android?</h1>
          <p className="lede">An Android app isn&rsquo;t ready just yet. If you leave us a way to get in touch, you&rsquo;ll be the first to know when it&rsquo;s ready.</p>
        </div>
      </div>
    </section>

    <section className="stripe stripe--alt stripe--centered" id="furry-form">
      <div className="container">
        <div className="copy">
          <h2 className="sectionTitle">Awesome. We&rsquo;ll let you know as soon as the Android app is ready to use with your pup.</h2>
        </div>
      </div>
    </section>
  </Layout>
);

export default AndroidThanksPage
